<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>위험대상물조합관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<!-- <div class="panel-heading">
				<h6 class="panel-title">
					<i class="icon-search4 position-left"></i>
					<b>검색조건</b>
				</h6>
				<div class="heading-elements">
					<ul class="icons-list">
						<li><a @click="pannelHidden" data-action="collapse"></a></li>
					</ul>
				</div>
			</div> -->
			<div class="panel-body" style="width: 1200px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<!-- 주의 작업명(ID) -->
						<!-- <div class="col-md-3" style="width: 250px">
							<div class="form-group">
								<label>{{ detailsFieldMap.warnWorkIdAndNm }}</label>
								<input
									type="text"
									class="form-control"
									style="width: 115px"
									:placeholder="detailsFieldMap.warnWorkIdAndNm"
									v-model="searchVM.warnWorkIdAndNm"
									:maxlength="maxLength.warnWorkIdAndNm"
								/>
							</div>
						</div> -->
						<!-- 위험대상물조합ID-->
						<div class="col-md-3" style="width: 300px">
							<div class="form-group">
								<label>{{ detailsFieldMap.objectAssoId }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									style="width: 135px"
									:placeholder="detailsFieldMap.objectAssoId"
									v-model="searchVM.objectAssoId"
									:maxlength="maxLength.objectAssoId"
									@keyup="checkItHasOnlyCapital('search', $event)"
								/>
							</div>
						</div>
						<!-- 위험대상물조합명 -->
						<div class="col-md-3" style="width: 300px">
							<div class="form-group">
								<label>{{ detailsFieldMap.objectAssoNm }}</label>
								<input
									type="text"
									class="form-control"
									style="width: 135px"
									:placeholder="detailsFieldMap.objectAssoNm"
									v-model="searchVM.objectAssoNm"
									:maxlength="maxLength.objectAssoNm"
								/>
							</div>
						</div>
						<!-- 위험대상물조합 설명 -->
						<div class="col-md-3" style="width: 280px">
							<div class="form-group">
								<label>{{ detailsFieldMap.objectAssoDscr }}</label>
								<input
									type="text"
									class="form-control"
									style="width: 200px"
									:placeholder="detailsFieldMap.objectAssoDscr"
									v-model="searchVM.objectAssoDscr"
									:maxlength="maxLength.objectAssoDscr"
								/>
							</div>
						</div>
						<!-- AI 적용 여부 -->
						<!-- <div class="col-md-3" style="width: 230px">
							<div class="form-group">
								<label style="width: 58%">{{ detailsFieldMap.aiAplyYn }}</label>
								<select2 v-model="searchVM.aiAplyYn">
									<option value="">전체</option>
									<option v-for="(row, index) in options.ynOptions" :key="index" :value="row.cd">{{ row.cdNm }}</option>
								</select2>
							</div>
						</div> -->
						<!-- 검색 버튼 -->
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								위험대상물조합 정보
							</legend>
							<div class="col-md-6">
								<!-- 위험대상물조합ID -->
								<jarvis-field
									:label="detailsFieldMap.objectAssoId"
									id="firstInputCreate"
									v-model="detailsItem.objectAssoId"
									required="true"
									:disabled="!isCreateMode"
									field="detailsItem.objectAssoId"
									data-vv-name="detailsItem.objectAssoId"
									v-validate="validationRule.detailsItem.objectAssoId"
									@keyup="checkItHasOnlyCapital('detail', $event)"
									:maxLength="maxLength.objectAssoId"
								></jarvis-field>
								<!-- 위험대상물조합명 -->
								<jarvis-field
									:label="detailsFieldMap.objectAssoNm"
									v-model="detailsItem.objectAssoNm"
									required="true"
									:disabled="!isCreateMode"
									field="detailsItem.objectAssoNm"
									data-vv-name="detailsItem.objectAssoNm"
									v-validate="validationRule.detailsItem.objectAssoNm"
									:maxLength="maxLength.objectAssoNm"
								></jarvis-field>
								<!-- 위험대상물조합 설명 -->
								<div class="form-group">
									<label class="col-lg-3 control-label" style="margin-right: 0px">{{ detailsFieldMap.objectAssoDscr }}:</label>
									<div class="col-lg-9">
										<textarea
											class="form-control"
											id="firstInputEdit"
											style="resize: none; height: 80px"
											v-model="detailsItem.objectAssoDscr"
											:placeholder="detailsItem.objectAssoDscr"
											:maxLength="maxLength.objectAssoDscr"
										></textarea>
									</div>
								</div>
								<!-- AI 적용 여부 -->
								<!-- <jarvis-field :label="detailsFieldMap.aiAplyYn" field="detailsItem.aiAplyYn" required="true">
									<select2 v-model="detailsItem.aiAplyYn" data-vv-name="detailsItem.aiAplyYn" v-validate="validationRule.detailsItem.aiAplyYn">
										<option value="">선택</option>
										<option v-for="(row, index) in options.ynOptions" :key="index" :value="row.cd">{{ row.cdNm }}</option>
									</select2>
								</jarvis-field> -->
								<!-- 등록일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 등록자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
								<!-- 수정일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 수정자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>

							<div class="col-md-6">
								<!-- 연결된 대상물 -->
								<div class="from-group">
									<div class="col-md-10" style="padding-left: 0px">
										<jarvis-field :label="detailsFieldMap.object">
											<select2 v-model="detailsItem.object" :options="options.objectOptions">
												<option value="">선택</option>
											</select2>
										</jarvis-field>
									</div>
									<div class="col-md-2" style="padding-left: 0px">
										<button
											type="button"
											class="btn btn-labeled bg-primary"
											style="padding-left: 10px"
											@click.prevent="addObjectRow(detailsItem.object)"
										>
											<i class="icon-plus3"></i>
										</button>
									</div>
									<!-- 대상물 table -->
									<div class="table-wrapper" ref="tableWrapperLaw">
										<table class="table">
											<thead>
												<tr>
													<th>대상물 ID</th>
													<th>대상물명</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<!-- @click="clickSiteSafetySupervisorRow(index)" -->
												<tr v-for="(row, index) in detailsItem.objects" :key="index">
													<!-- <td v-model="row.objectId">{{ row.objectId }}</td>
													<td v-model="row.objectNm">{{ row.objectNm }}</td> -->
													<td>{{ row.objectId }}</td>
													<td>{{ row.objectNm }}</td>
													<td style="padding-left: 0px; padding-right: 8px">
														<button class="btn btn-xs btn-default" @click.prevent="delObjectRow(index)">
															<i class="fa fa-minus"></i>
														</button>
													</td>
												</tr>
												<tr empty>
													<td colspan="3">추가된 대상물이 없습니다</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention; //eslint-disable-line no-unused-vars
const warnWorkApi = apiIndex.warnWork;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		objectAssoId: 'max:4|required',
		objectAssoNm: 'max:100|required',
		objectAssoDscr: 'max:4000',
		// aiAplyYn: 'required',
	},
	edit: {
		//수정
	},
	create: {
		//생성
	},
};

const maxLength = {
	// warnWorkIdAndNm: '100';
	objectAssoId: '4',
	objectAssoNm: '100',
	objectAssoDscr: '4000',
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		Select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('warnWorkPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		// 기존 라이브러리를 사용하기 위해 변환
		// this.pageParam.ynOptions.forEach(e => {
		// 	e.text = e.cdNm;
		// 	e.value = e.cd;
		// });

		this.pageParam.objectOptions.forEach(e => {
			e.text = e.objectId + ' - ' + e.objectNm;
			e.value = e.objectId + ' - ' + e.objectNm;
		});
		this.options = {
			// ynOptions: this.pageParam.ynOptions,
			objectOptions: this.pageParam.objectOptions,
		};
		// this.gridColumns.filter(item => item.field == 'aiAplyYn')[0].mapData = this.options.ynOptions;

		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });
		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			pageListApi: warnWorkApi.inqWarnWork,
			excelApi: warnWorkApi.exlWarnWork,
			detailsApi: warnWorkApi.inqOneWarnWork,
			delApi: warnWorkApi.delWarnWork,
			insApi: warnWorkApi.insWarnWork,
			updApi: warnWorkApi.updWarnWork,
		},
		//검색VM
		searchVM: {
			// warnWorkIdAndNm: null,
			objectAssoId: null,
			objectAssoNm: null,
			objectAssoDscr: null,
			// aiAplyYn: null,
		},
		searchState: {}, //적용된 검색조건
		//그리드 설정
		gridColumns: [
			{ field: 'objectAssoId', title: '위험대상물조합ID' },
			{ field: 'objectAssoNm', title: '위험대상물조합명' },
			{ field: 'objectAssoDscr', title: '설명', overFlowHidden: true },
			// {
			// 	field: 'aiAplyYn',
			// 	title: 'AI 적용 여부',
			// 	width: '10%',
			// 	align: 'center',
			// 	dataFormat: 'optionMap',
			// },
			{ field: 'regDtm', title: '등록일', width: '10%', align: 'center', dataFormat: 'YYYY-MM-DD' },
			{ field: 'updDtm', title: '수정일', width: '10%', align: 'center', dataFormat: 'YYYY-MM-DD' },
		],
		options: {},
		//상세필드명맵
		detailsFieldMap: {
			warnWorkIdAndNm: '위험대상물조합명(ID)',
			objectAssoId: '위험대상물조합ID',
			objectAssoNm: '위험대상물조합명',
			objectAssoDscr: '설명',
			// aiAplyYn: 'AI 적용 여부',
			object: '대상물',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '수정일',
			updUser: '수정자',
		},
		//인풋 최댓값 설정
		maxLength,
		detailsItemOriginal: {}, //상세편집 원본
		detailsItem: {}, //상세편집 수정본
		debug: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				rule = detailsValidationRule.edit;
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		putValueInInput(data, value) {
			if (data == 'search') {
				this.searchVM['objectAssoId'] = value;
			} else {
				this.detailsItem['objectAssoId'] = value;
			}
		},
		changeSmallLetterToCap(value) {
			let valueArr = value.split('');
			let lengthOfvalue = valueArr.length;
			for (let i = 0; i < lengthOfvalue; i++) {
				if (valueArr[i].charCodeAt() >= 97 && valueArr[i].charCodeAt() <= 122) {
					valueArr[i] = value[i].toUpperCase();
				}
			}
			return valueArr.join('');
		},
		checkItHasOnlyCapital(data, event) {
			let target = event.target;
			target.value = this.changeSmallLetterToCap(target.value);
			target.value = this.$jarvisExtention.inputDataController.onlyCapital(target.value);
			this.putValueInInput(data, target.value);
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName =
						'위험대상물조합관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				this.$axios
					.post(this.apiUrl.detailsApi, { objectAssoId: selectedRowItem.objectAssoId })
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true, {}, response.data);
							this.detailsItem = $.extend(true, {}, response.data);
							setTimeout(() => this.focusFirstInput('firstInputEdit'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				objectAssoId: null,
				objectAssoNm: null,
				objectAssoDscr: null,
				// aiAplyYn: null,
				object: null,
				objects: [],
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//신규저장
		createData() {
			const closure = this;
			this.$validator.validateAll().then(success => {
				if (success === false) {
					return;
				}
				closure.$axios
					.post(closure.apiUrl.insApi, closure.detailsItem)
					.then(
						function (r) {
							r;
							closure.loadGrid();
						}.bind(closure),
					)
					.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
			});
		},
		//수정저장
		updateData() {
			const closure = this;
			this.$validator.validateAll().then(success => {
				if (success === false) {
					return;
				}
				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					this.detailsItem.oriObjects = this.detailsItemOriginal.objects;

					closure.$axios
						.post(closure.apiUrl.updApi, closure.detailsItem)
						.then(
							function (r) {
								r;
								closure.loadGrid();
							}.bind(closure),
						)
						.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			this.$axios
				.post(warnWorkApi.delWarnWork, this.detailsItem)
				.then(
					function () {
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		addObjectRow(_object) {
			// object는 objectId - objectNm 형태
			if (_object === null) {
				return;
			}

			let objectId = _object.split('-')[0].trim();
			let objectNm = _object.split('-')[1].trim();

			let isObjectDuplicate = false; // 리스트 중복 확인

			this.detailsItem.objects.forEach((e, index) => {
				if (e.objectId.trim() === objectId) {
					alert('해당 대상물은 이미 추가되어 있습니다');
					index;
					isObjectDuplicate = true;
				}
			});

			if (isObjectDuplicate) {
				return;
			}

			const object = { objectId, objectNm };

			this.detailsItem.objects.push(object);
		},
		delObjectRow(index) {
			this.detailsItem.objects.splice(index, 1);
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	margin-top: 5px;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
	width: 33%;
	text-align: center;
}

.table th:last-child {
	width: 10%;
}

/* .panel-body .table th:first-child {
	width: 120px;
	text-align: center;
} */

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}
.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}
label {
	margin-right: 15px;
}
</style>
